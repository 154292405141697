import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function RichEditor({
  value,
  setValue,
  placeholder = "Write something awesome...",
}) {
  return (
    <div className="bg-white">
      <ReactQuill value={value} onChange={setValue} placeholder={placeholder} />
    </div>
  );
}

export default RichEditor;
