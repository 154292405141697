import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Sidebar from "../components/Sidebar/Sidebar.js";
import HeaderStats from "../components/Headers/HeaderStats.js";
import FooterAdmin from "../components/Footers/FooterAdmin.js";

import CreatePost from "../views/admin/Settings.js";

import UpdatePost from "../views/admin/Update.js";

import Users from "../views/admin/Users.js";

export default function Admin() {
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("cholonshoi-token"));

    if (!user) {
      window.location.href = "/auth/login";
    }
  }, []);

  return (
    <>
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* Header */}
        <HeaderStats />
        <div className="w-full min-h-[80vh] px-4 mx-auto -m-24 md:px-10">
          <Switch>
            <Route path="/admin/createpost" exact component={CreatePost} />
            <Route path="/admin/update/:postId" exact component={UpdatePost} />

            <Route path="/admin/users" exact component={Users} />
            <Redirect from="/admin" to="/admin/users" />
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
