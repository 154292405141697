import React from "react";

// components

export default function HeaderStats() {
  return (
    <>
      {/* Header */}
      <div className="relative pt-12 pb-32 bg-lightBlue-600 md:pt-32"></div>
    </>
  );
}
