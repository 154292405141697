import React from "react";
import { useMutation } from "react-query";
import {
  userAdminToggleMutation,
  userUpdateMutation,
} from "../../resolvers/mutation";
import toast from "react-hot-toast";

const UsersTable = ({ color = "light", users = [] }) => {
  const { mutate, isLoading } = useMutation({
    mutationKey: ["updateUser"],
    mutationFn: userUpdateMutation,
  });

  const { mutate: userMakeAdmin, isLoading: makeAdminLoading } = useMutation({
    mutationKey: ["makeAdmin"],
    mutationFn: userAdminToggleMutation,
  });

  const handleApprove = (userId, can_login) => {
    mutate(
      {
        variables: {
          can_login: can_login ? 0 : 1,
        },
        userId,
      },
      {
        onSuccess: () => {
          toast.success("User Approved");
          window.location.reload();
        },
        onError: () => {
          toast.error("Something went wrong!");
        },
      }
    );
  };

  const makeAdminHandler = (userId, makeAdmin) => {
    userMakeAdmin(
      {
        isAdmin: makeAdmin ? 0 : 1,
        userId,
      },
      {
        onSuccess: () => {
          toast.success("User made admin");
          window.location.reload();
        },
        onError: () => {
          toast.error("Something went wrong!");
        },
      }
    );
  };

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="px-4 py-3 mb-0 border-0 rounded-t">
          <div className="flex flex-wrap items-center">
            <div className="relative flex-1 flex-grow w-full max-w-full px-4">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                All Users
              </h3>
            </div>
          </div>
        </div>
        <div className="block w-full overflow-x-auto">
          {/* Projects table */}
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Phone Number
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Approved / Not Approved
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Is Admin
                </th>

                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                ></th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr key={user.id}>
                  <th
                    className={
                      "border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left " +
                      (color === "light"
                        ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                    }
                  >
                    {user.name}
                  </th>
                  <td className="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                    {user.phone_number}
                  </td>
                  <td className="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                    {user?.can_login ? (
                      <span className="px-2 py-1 text-xs font-bold leading-normal text-green-600 bg-green-100 rounded-full">
                        Approved
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-xs font-bold leading-normal text-red-600 bg-red-100 rounded-full">
                        Not Approved
                      </span>
                    )}
                  </td>
                  <td className="p-4 px-6 text-xs align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                    {user?.isAdmin ? (
                      <span className="px-2 py-1 text-xs font-bold leading-normal text-green-600 bg-green-100 rounded-full">
                        Admin
                      </span>
                    ) : (
                      <span className="px-2 py-1 text-xs font-bold leading-normal text-red-600 bg-red-100 rounded-full">
                        Not Admin
                      </span>
                    )}
                  </td>

                  <td className="p-4 px-6 text-xs text-right align-middle border-t-0 border-l-0 border-r-0 whitespace-nowrap">
                    <button
                      className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-800 rounded shadow outline-none active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                      onClick={() => {
                        handleApprove(user.id, user.can_login);
                      }}
                    >
                      {user?.can_login ? "Disapprove" : "Approve"}
                    </button>

                    <button
                      className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-gray-800 rounded shadow outline-none active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                      onClick={() => {
                        makeAdminHandler(user.id, user.isAdmin);
                      }}
                    >
                      {user?.isAdmin ? "Remove Admin" : "Make Admin"}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default UsersTable;
