import React from "react";
import { useHistory } from "react-router-dom";

const Filter = ({ refetch }) => {
  const history = useHistory();
  const [state, setState] = React.useState({
    search: "",
  });
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container max-w-screen-md pt-20 m-2 mx-auto">
      <div className="p-6 bg-white border border-gray-200 shadow-lg rounded-xl">
        <div className="grid grid-cols-1 gap-6 ">
          <div className="flex flex-col">
            <label for="search" className="text-sm font-medium text-stone-600">
              Search
            </label>
            <input
              type="text"
              id="search_input"
              placeholder="Search"
              className="block w-full px-2 py-2 mt-2 border border-gray-200 rounded-md shadow-sm outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
              name="search"
              value={state.search}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="grid justify-end w-full grid-cols-2 mt-6 space-x-4 md:flex">
          <button
            className="px-8 py-2 font-medium text-white bg-blue-600 rounded-lg outline-none active:scale-95 focus:ring hover:opacity-90"
            onClick={() => {
              history.push();
              window.location.replace(`/?search=${state.search}`);
            }}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default Filter;
