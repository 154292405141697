import RichTextEditor from "../../components/Editor/RichTextEditor";

import React from "react";
import { useMutation } from "react-query";
import { createPostMutation } from "../../resolvers/mutation";
import toast from "react-hot-toast";

// components

export default function UpdateCardSettings() {
  const [details, setDetails] = React.useState("");
  const [photos, setPhotos] = React.useState("");

  const { mutate, isLoading } = useMutation({
    mutationKey: "createPost",
    mutationFn: createPostMutation,
  });

  const handleSubmit = (e) => {
    const formData = new FormData();

    formData.append("details", details);

    for (let i = 0; i < photos.length; i++) {
      formData.append("photos", photos[i]);
    }

    mutate(
      { variables: formData },
      {
        onSuccess: (data) => {
          toast.success("Post created successfully");
        },
        onError: (error) => {
          toast.error("Error creating post");
          console.log(error);
        },
      }
    );
  };

  return (
    <>
      <div className="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100">
        <div className="px-6 py-6 mb-0 bg-white rounded-t">
          <div className="flex justify-between text-center">
            <h6 className="text-xl font-bold text-blueGray-700">Update Post</h6>
            <button
              className="px-4 py-2 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-lightBlue-500 active:bg-lightBlue-600 hover:shadow-md focus:outline-none"
              type="button"
              disabled={isLoading}
              onClick={handleSubmit}
            >
              {isLoading ? "Updating..." : "Update"}
            </button>
          </div>
        </div>
        <div className="flex-auto px-4 py-10 pt-0 lg:px-10">
          <div>
            <div className="flex flex-wrap mt-2"></div>

            <hr className="my-6 border-b-1 border-blueGray-300" />

            <hr className="my-6 border-b-1 border-blueGray-300" />

            <div className="w-full px-4 ">
              <div className="relative w-full mb-3">
                <label
                  className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                  htmlFor="grid-price"
                >
                  Details
                </label>
                <RichTextEditor value={details} setValue={setDetails} />
              </div>
            </div>

            <div className="flex flex-wrap">
              <div className="w-full px-4 lg:w-12/12">
                <div className="relative w-full mb-3">
                  <label
                    className="block mb-2 text-xs font-bold uppercase text-blueGray-600"
                    htmlFor="grid-photos"
                  >
                    Photos
                  </label>
                  <input
                    type="file"
                    multiple
                    className="w-full px-3 py-3 text-sm transition-all duration-150 ease-linear bg-white border-0 rounded shadow placeholder-blueGray-300 text-blueGray-600 focus:outline-none focus:ring"
                    name="photos"
                    onChange={(e) => setPhotos(e.target.files)}
                    placeholder="https://www.creative-tim.com"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
