import React from "react";

// components

import UpdateCardSettings from "../../components/Cards/UpdateCardSettings.js";

export default function UpdatePost() {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="w-full px-4 ">
          <UpdateCardSettings />
        </div>
      </div>
    </>
  );
}
