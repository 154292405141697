import React from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { useSelector } from "react-redux";

const IndexDropdown = () => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const user = useSelector((state) => state.user);

  return (
    <>
      <a
        className="flex items-center px-3 py-4 text-xs font-bold uppercase hover:text-blueGray-500 text-blueGray-700 lg:py-2"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        {user?.user?.isAdmin ? "Admin Support" : "User Support"}
      </a>
      <di
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48"
        }
      >
        <span
          className={
            "text-sm pt-2 pb-0 px-4 font-bold block w-full whitespace-nowrap bg-transparent text-blueGray-400"
          }
        >
          {user?.user?.isAdmin ? "Admin Layout" : "User Layout"}
        </span>

        {user?.user?.isAdmin ? (
          <>
            {" "}
            <Link
              to="/admin/users"
              className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
            >
              Users
            </Link>
            <Link
              to="/admin/createpost"
              className="block w-full px-4 py-2 text-sm font-normal bg-transparent whitespace-nowrap text-blueGray-700"
            >
              Create Post
            </Link>
          </>
        ) : null}
        <div className="px-4 mt-5">
          <button
            className="block w-full px-4 py-2 text-sm font-normal text-white bg-red-600 rounded-md whitespace-nowrap "
            onClick={() => {
              localStorage.removeItem("cholonshoi-token");
              window.location.href = "/auth/login";
            }}
          >
            Logout{" "}
          </button>
        </div>
      </di>
    </>
  );
};

export default IndexDropdown;
