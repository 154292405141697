import axios from "axios";

const API_URL = "https://api.cholonshoi.com/api";

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

const multipartConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

export const registerUserMutation = async ({ variables }) =>
  axios
    .post(`${API_URL}/user/register`, variables, config)
    .then((res) => res.data);
export const createPostMutation = async ({ variables }) =>
  axios
    .post(`${API_URL}/post/create`, variables, multipartConfig)
    .then((res) => res.data);

export const loginMutation = async ({ variables }) =>
  axios
    .post(`${API_URL}/user/login`, variables, config)
    .then((res) => res.data);

export const postDeleteMutation = async ({ postId }) =>
  axios
    .delete(`${API_URL}/post/delete/${postId}`, config)
    .then((res) => res.data);

export const userUpdateMutation = async ({ variables, userId }) =>
  axios
    .put(`${API_URL}/user/update/${userId}`, variables, config)
    .then((res) => res.data);

export const userAdminToggleMutation = async ({ userId, isAdmin }) =>
  axios
    .put(
      `${API_URL}/user/toggleAdmin`,
      {
        id: userId,
        isAdmin,
      },
      config
    )
    .then((res) => res.data);
