import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="relative pt-8 pb-6 bg-blueGray-200">
        <div className="container px-4 mx-auto">
          <hr className="my-6 border-blueGray-300" />
          <div className="flex flex-wrap items-center justify-center md:justify-between">
            <div className="w-full px-4 mx-auto text-center md:w-4/12">
              <div className="py-1 text-sm font-semibold text-blueGray-500">
                Copyright © {new Date().getFullYear()} Cholonshoi React by{" "}
                <a
                  href="https://nvmsoft.com/"
                  className="text-blueGray-500 hover:text-blueGray-800"
                >
                  nvmsoft
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
