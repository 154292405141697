/*eslint-disable*/
import React, { useEffect } from "react";

import IndexNavbar from "../components/Navbars/IndexNavbar.js";
import Footer from "../components/Footers/Footer.js";
import Filter from "../components/UI/Filter";
import IndexCard from "../components/Post/IndexCard";
import { useQuery } from "react-query";
import { getAllPosts } from "../resolvers/query";

import { useLocation } from "react-router-dom";

// Example items, to simulate fetching from another resources.

export default function Index() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const searchQuery = searchParams.get("search");

  // Get the items for the current page.

  const { data, isLoading, isError, isFetching, refetch } = useQuery({
    queryKey: ["posts"],
    queryFn: () =>
      getAllPosts({
        search: searchQuery || "",
        page: 0,
      }),
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("cholonshoi-token"));

    if (!user) {
      window.location.href = "/auth/login";
    }
  }, []);

  // Simulate fetching items from another resources.
  // (This could be items from props; or items loaded in a local state
  // from an API endpoint with useEffect and useState)

  if (isLoading || isFetching) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="flex items-center justify-center space-x-1 text-sm text-gray-700">
          <svg
            fill="none"
            className="w-6 h-6 animate-spin"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>

          <div>Loading ...</div>
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center w-full h-screen">
        <div className="flex items-center justify-center space-x-1 text-sm text-gray-700">
          <div>Error </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-blueGray-200">
      <IndexNavbar fixed refetch={refetch} />
      <Filter refetch={refetch} />

      <div>
        {data?.posts?.map((post) => (
          <IndexCard key={post.id} post={post} refetch={refetch} />
        ))}
      </div>
      <Footer />
    </div>
  );
}
