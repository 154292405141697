import axios from "axios";

const API_URL = "https://api.cholonshoi.com/api";

export const getAllPosts = async ({ search = "", page = 0 }) =>
  axios
    .get(`${API_URL}/post?search=${search}&page=${page}`)
    .then((res) => res.data);
export const getPostById = async ({ id }) =>
  axios.get(`${API_URL}/post/${id}`).then((res) => res.data);

export const getAllUsers = async () =>
  axios.get(`${API_URL}/user/users`).then((res) => res.data);
