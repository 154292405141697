/* eslint-disable no-restricted-globals */
import React from "react";
import { ReactPhotoCollage } from "react-photo-collage";
import textVersion from "textversionjs";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useMutation } from "react-query";
import { postDeleteMutation } from "../../resolvers/mutation";

const IndexCard = ({ post, refetch }) => {
  const user = useSelector((state) => state.user.user);

  const setting = {
    width: "800px",
    height: ["400px", "200px"],
    layout: [1, 4],
    photos: post?.photos?.map((photo) => {
      return {
        source: `${"https://api.cholonshoi.com"}/${photo.url}`,
      };
    }),
    showNumOfRemainingPhotos: true,
  };

  const { mutate, isLoading } = useMutation({
    mutationKey: "deletePost",
    mutationFn: postDeleteMutation,
  });

  const handleCopy = (e) => {
    e.preventDefault();
    const text = textVersion(post?.details);
    navigator.clipboard.writeText(text);

    toast.success("Copied to clipboard");
  };

  const handleDeletePost = async (e) => {
    e.preventDefault();

    mutate(
      { postId: post?.id },
      {
        onSuccess: (data) => {
          toast.success("Post deleted successfully");
          refetch();
        },
        onError: (error) => {
          console.log(error);
        },
      }
    );
  };

  return (
    <section className="relative py-5 bg-blueGray-200">
      <div className="container mx-auto">
        <div className="relative z-10 flex flex-wrap justify-center px-4 py-16 bg-white rounded-lg shadow-xl md:px-6">
          <div className="container mx-auto">
            <div className="flex items-center justify-end">
              <button
                className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-blueGray-800 active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                onClick={handleCopy}
              >
                Copy Details
              </button>
              {user?.isAdmin ? (
                <>
                  <button
                    className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-600 rounded shadow outline-none active:bg-blueGray-600 hover:shadow-lg focus:outline-none"
                    onClick={handleDeletePost}
                  >
                    Delete
                  </button>
                </>
              ) : null}
            </div>
            <div>
              <div
                className="py-2"
                dangerouslySetInnerHTML={{ __html: post?.details }}
              ></div>
            </div>
            <div className="flex items-center justify-center mt-10">
              {post?.photos?.length <= 0 ? (
                <div>no photo</div>
              ) : (
                <ReactPhotoCollage {...setting} />
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndexCard;
