import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUser } from "../redux/slices/userSlice.js";

const LocalStorageProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const user = localStorage.getItem("cholonshoi-token");

    if (user) {
      dispatch(setUser(JSON.parse(user)));
    }
  }, [dispatch]);

  return <>{children}</>;
};

export default LocalStorageProvider;
