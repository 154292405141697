import React from "react";

// components

import { useQuery } from "react-query";
import { getAllUsers } from "../../resolvers/query.js";
import UsersTable from "../../components/Cards/UsersTable.js";

export default function Users() {
  const { data, isLoading, isError } = useQuery({
    queryKey: ["users"],
    queryFn: getAllUsers,
  });

  return (
    <>
      <div className="flex flex-wrap mt-4">
        <div className="w-full px-4 mb-12">
          {isLoading ? (
            <div>Loading...</div>
          ) : isError ? (
            <div>Something went wrong!</div>
          ) : (
            <UsersTable users={data || []} />
          )}
        </div>
      </div>
    </>
  );
}
